(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var submitButton = document.getElementById('age-submit');
var errorMessage = document.getElementById('age-error');
var inputs = document.querySelectorAll('.age-gate__input');
var dayInput = document.getElementById('age-day');
var monthInput = document.getElementById('age-month');
var yearInput = document.getElementById('age-year');

var showError = function showError() {
  inputs.forEach(function (el) {
    el.classList.add('error');
  });
  errorMessage.classList.add('error');
};

var clearError = function clearError() {
  inputs.forEach(function (el) {
    el.classList.remove('error');
  });
  errorMessage.classList.remove('error');
};

submitButton.addEventListener('click', function () {
  var day = parseInt(dayInput.value);
  var month = parseInt(monthInput.value);
  var year = parseInt(yearInput.value);
  var inputDate = new Date(year, month - 1, day);
  var eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  if (inputDate <= eighteenYearsAgo) {
    window.location.href = 'https://blend-session.pl/Blend_Session_Regulamin_Konkursu.pdf';
  } else {
    showError();
    inputs.forEach(function (el) {
      el.addEventListener('click', function () {
        clearError();
      });
    });
  }
});

},{}],2:[function(require,module,exports){
"use strict";

require("./components/home");

var appHeight = function appHeight() {
  var doc = document.documentElement;
  doc.style.setProperty('--app-height', "".concat(window.innerHeight, "px"));
};

window.addEventListener('resize', appHeight);
appHeight();

},{"./components/home":1}]},{},[2]);
